<p-button-pure
  cdkOverlayOrigin
  #originOverlay="cdkOverlayOrigin"
  [active]="active"
  [hideLabel]="true"
  [iconSource]="'assets/icons/filter.svg'"
  [size]="'x-small'"
  (click)="open = !open"
>
  Filters
</p-button-pure>

<ng-template
  cdk-connected-overlay
  [cdkConnectedOverlayOrigin]="originOverlay"
  [cdkConnectedOverlayOpen]="open"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="open = false"
  (detach)="open = false"
>
  <div class="table-filter-menu" [class.theme-dark]="(theme$ | async) === 'dark'">
    <ng-content />
  </div>
</ng-template>
