<opa-theme-loader />

<opa-header />

<opa-navbar />

<main class="main">
  <router-outlet />
</main>

@defer (when isChristmas) {
  <opa-snow />
}

<opa-error-banner />

<p-toast />
