/* eslint-disable @typescript-eslint/naming-convention */
import { Pipe, type PipeTransform } from '@angular/core';
import { PdhrColors } from '../../constants/colors.constants';

@Pipe({
  name: 'opaUserIconColor',
  standalone: true,
})
export class UserIconColorPipe implements PipeTransform {
  transform(name: string, isCounter: boolean): string | undefined {
    if (isCounter) return undefined;

    const colors = Object.values(PdhrColors);
    if (!name) return colors[0];

    const sum = name
      .split('')
      .map((part) => part.charCodeAt(0))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return colors[sum % colors.length];
  }
}
