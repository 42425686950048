import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  importProvidersFrom,
  isDevMode,
  type ApplicationConfig,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideTransloco } from '@ngneat/transloco';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { routes } from './app.routes';
import { getTheme } from './shared/helpers/theme.helpers';
import { authInterceptor } from './shared/interceptors/auth.interceptor';
import { httpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { AuthService } from './shared/services/auth.service';
import { TranslocoHttpLoader } from './shared/services/transloco.loader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideOAuthClient(),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthService],
      useFactory: (authService: AuthService) => () => authService.initialize$(),
    },
    provideRouter(routes),
    provideHttpClient(withInterceptors([authInterceptor, httpErrorInterceptor])),
    provideTransloco({
      config: {
        availableLangs: ['en-GB'],
        defaultLang: 'en-GB',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    importProvidersFrom(PorscheDesignSystemModule.load({ theme: getTheme() })),
  ],
};
