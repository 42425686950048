import { Component, Input, type OnChanges } from '@angular/core';
import type { NgChanges } from '../../models/ng-changes.model';

@Component({
  selector: 'opa-maybe-url',
  standalone: true,
  templateUrl: './maybe-url.component.html',
  styleUrl: './maybe-url.component.scss',
})
export class MaybeUrlComponent implements OnChanges {
  @Input({ required: true }) url: string | null | undefined = undefined;

  protected isUrl = this.calculateIsUrl(this.url);

  ngOnChanges(changes: NgChanges<MaybeUrlComponent>): void {
    if (changes.url) this.isUrl = this.calculateIsUrl(this.url);
  }

  private calculateIsUrl(maybeUrl: string | null | undefined): boolean {
    return maybeUrl != null ? URL.canParse(maybeUrl) : false;
  }
}
