// TODO: real time theme change breaks on navigation
// check if fixed with new versions of PDS or report

const storageKeyTheme = 'opa-theme';

export function setTheme(theme: 'light' | 'dark'): void {
  localStorage.setItem(storageKeyTheme, theme);
  window.location.reload();
}

export function getTheme(): 'light' | 'dark' {
  const stored = localStorage.getItem(storageKeyTheme);
  if (stored === 'light' || stored === 'dark') return stored;
  else return 'light';
}
