<p-button-pure
  cdkOverlayOrigin
  #originOverlay="cdkOverlayOrigin"
  [hideLabel]="true"
  [iconSource]="'assets/icons/table-settings.svg'"
  [size]="'x-small'"
  (click)="open = !open"
>
  Filters
</p-button-pure>

<ng-template
  cdk-connected-overlay
  [cdkConnectedOverlayOrigin]="originOverlay"
  [cdkConnectedOverlayOpen]="open"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="open = false"
  (detach)="open = false"
>
  <div
    *ngrxLet="visibilityData$ as visibilityData; suspenseTpl: spinner"
    class="table-column-menu"
    [class.theme-dark]="visibilityData.theme === 'dark'"
  >
    <p-checkbox-wrapper
      *ngFor="let column of visibilityData.columns"
      [label]="column.translationKey | transloco"
    >
      <input
        type="checkbox"
        [disabled]="column.alwaysVisible"
        [value]="column.columnName"
        [checked]="column.visible"
        (click)="toggleVisibility(column.tableName, column.columnName)"
      />
    </p-checkbox-wrapper>
  </div>
</ng-template>

<ng-template #spinner>
  <div class="loading-spinner"><p-spinner /></div>
</ng-template>
