import { CommonModule } from '@angular/common';
import { Component, inject, ViewEncapsulation } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { THEME_TOKEN } from '@porsche-design-system/components-angular';
import { tap } from 'rxjs';

@Component({
  selector: 'opa-theme-loader',
  standalone: true,
  imports: [CommonModule, LetDirective],
  templateUrl: './theme-loader.component.html',
  styleUrl: './theme-loader.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ThemeLoaderComponent {
  private readonly theme = inject(THEME_TOKEN);

  readonly theme$ = this.theme.pipe(
    tap((theme) => {
      if (theme === 'dark') document.body.classList.add('theme-dark');
      else document.body.classList.remove('theme-dark');
    }),
  );
}
