import { OverlayModule, type ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule, THEME_TOKEN } from '@porsche-design-system/components-angular';
import { ReplaySubject, combineLatest, map, type Observable } from 'rxjs';
import { VisibleColumnsService } from '../paged-table/visible-columns.service';
import type { ColumnWithTranslation, VisibilityData } from './visible-columns-picker.models';

@Component({
  selector: 'opa-visible-columns-picker',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, OverlayModule, TranslocoPipe, LetDirective],
  templateUrl: './visible-columns-picker.component.html',
  styleUrls: ['./visible-columns-picker.component.scss'],
})
export class VisibleColumnsPickerComponent {
  @Input({ required: true }) set columnsWithTranslations(columns: Record<string, string>) {
    this._columns.next(
      Object.entries(columns).map(([columnName, columnNameTranslationKey]) => ({
        columnName,
        columnNameTranslationKey,
      })),
    );
  }

  @Input({ required: true }) set alwaysVisibleColumns(columns: string[]) {
    this._alwaysVisibleColumns.next(columns);
  }

  @Input({ required: true }) set tableName(key: string) {
    this._tableName.next(key);
  }

  private readonly visibleColumnsService = inject(VisibleColumnsService);
  private readonly theme = inject(THEME_TOKEN);
  private readonly _alwaysVisibleColumns = new ReplaySubject<string[]>(1);
  private readonly _columns = new ReplaySubject<ColumnWithTranslation[]>(1);
  private readonly _tableName = new ReplaySubject<string>(1);

  readonly visibilityData$ = this.getVisibilityData$();
  open = false;

  readonly positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ];

  toggleVisibility(table: string, columnName: string): void {
    this.visibleColumnsService.toggleColumnVisibility(table, columnName);
  }

  private getVisibilityData$(): Observable<VisibilityData> {
    const data = combineLatest([
      this._columns.asObservable(),
      this._alwaysVisibleColumns.asObservable(),
      this._tableName.asObservable(),
      this.theme.asObservable(),
    ]).pipe(
      map(([columns, alwaysVisibleColumns, tableName, theme]) => {
        const visibleColumns = this.visibleColumnsService.getVisibleColumns(tableName);

        const columnsData = columns.map((column) => {
          const visible = visibleColumns.includes(column.columnName);
          const alwaysVisible = alwaysVisibleColumns.includes(column.columnName);

          if (!visible && alwaysVisible) {
            this.visibleColumnsService.toggleColumnVisibility(tableName, column.columnName);
          }

          return {
            columnName: column.columnName,
            translationKey: column.columnNameTranslationKey,
            visible,
            alwaysVisible,
            tableName,
          };
        });

        return {
          tableName,
          columns: columnsData,
          theme,
        };
      }),
    );

    return data;
  }
}
