import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HolidayService {
  /** Between yyyy-12-01 and yyyy-01-06 */
  isChristmas(): boolean {
    const now = new Date();
    const year = now.getFullYear();
    const start = new Date(year, 11, 1);
    const end = new Date(year, 0, 6);
    return now >= start || now <= end;
  }
}
