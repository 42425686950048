import { Component } from '@angular/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';

@Component({
  selector: 'opa-pdhr-logo',
  standalone: true,
  imports: [PorscheDesignSystemModule],
  templateUrl: './pdhr-logo.component.html',
  styleUrl: './pdhr-logo.component.scss',
})
export class PdhrLogoComponent {}
