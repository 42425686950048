/** WARN: PDS variables */
export const PdsColors = {
  Brand: '#d5001c',
  ThemeLightPrimary: '#010205',
  ThemeLightBackgroundBase: '#fff',
  ThemeLightBackgroundSurface: '#eeeff2',
  ThemeLightBackgroundShading: 'rgba(1, 2, 5, 0.67)',
  ThemeLightContrastLow: '#d8d8db',
  ThemeLightContrastMedium: '#6b6d70',
  ThemeLightContrastHigh: '#535457',
  ThemeLightNotificationSuccess: '#197e10',
  ThemeLightNotificationSuccessSoft: '#e4ffec',
  ThemeLightNotificationWarning: '#f3be00',
  ThemeLightNotificationWarningSoft: '#fff4d2',
  ThemeLightNotificationError: '#cc1922',
  ThemeLightNotificationErrorSoft: '#ffe2e4',
  ThemeLightNotificationInfo: '#2762ec',
  ThemeLightNotificationInfoSoft: '#d3e1ff',
  ThemeLightStateHover: 'rgba(148, 149, 152, 0.18)',
  ThemeLightStateActive: 'rgba(148, 149, 152, 0.2)',
  ThemeLightStateFocus: '#1a44ea',
  ThemeLightStateDisabled: '#949598',
  ThemeDarkPrimary: '#fbfcff',
  ThemeDarkBackgroundBase: '#0e0e12',
  ThemeDarkBackgroundSurface: '#212225',
  ThemeDarkBackgroundShading: 'rgba(38, 38, 41, 0.67)',
  ThemeDarkContrastLow: '#404044',
  ThemeDarkContrastMedium: '#88898c',
  ThemeDarkContrastHigh: '#afb0b3',
  ThemeDarkNotificationSuccess: '#09d087',
  ThemeDarkNotificationSuccessSoft: '#003320',
  ThemeDarkNotificationWarning: '#f7cb47',
  ThemeDarkNotificationWarningSoft: '#362b0a',
  ThemeDarkNotificationError: '#fc4040',
  ThemeDarkNotificationErrorSoft: '#3a0f0f',
  ThemeDarkNotificationInfo: '#178bff',
  ThemeDarkNotificationInfoSoft: '#04294e',
  ThemeDarkStateHover: 'rgba(148, 149, 152, 0.18)',
  ThemeDarkStateActive: 'rgba(126, 127, 130, 0.2)',
  ThemeDarkStateFocus: '#1a44ea',
  ThemeDarkStateDisabled: '#7e7f82',
} as const;

export const PdhrColors = {
  Lime: '#DBD522',
  Orange: '#F2944B',
  Eggplant: '#A85F9A',
  Peach: '#FF6F6A',
  Rose: '#FF63AE',
  Leaf: '#95A84A',
  Mint: '#00C9B2',
  Eucalyptus: '#00B6C3',
  Water: '#00AFFF',
  Plum: '#0075D1',
} as const;
