import { OverlayModule, type ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { PorscheDesignSystemModule, THEME_TOKEN } from '@porsche-design-system/components-angular';

@Component({
  selector: 'opa-table-filter',
  standalone: true,
  imports: [CommonModule, PorscheDesignSystemModule, OverlayModule],
  templateUrl: './table-filter.component.html',
  styleUrl: './table-filter.component.scss',
})
export class TableFilterComponent {
  private readonly theme = inject(THEME_TOKEN);

  @Input({ required: true }) active = false;

  open = false;
  readonly theme$ = this.theme.asObservable();
  readonly positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ];
}
