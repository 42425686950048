import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import {
  EMPTY,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
  type Observable,
} from 'rxjs';
import { MaybeUrlComponent } from '../../shared/components/maybe-url/maybe-url.component';
import { objectsEqual } from '../../shared/helpers/objects-equal.helper';
import type { Event } from '../../shared/models/api/event.model';
import type { Transaction } from '../../shared/models/api/transaction.model';
import { EventService } from '../../shared/services/api/event.service';
import { AuthService } from '../../shared/services/auth.service';
import { MediaQueryService } from '../../shared/services/media-query.service';
import { eventTypeTranslation } from '../event-create/event-create.helpers';

@Component({
  selector: 'opa-event-details',
  standalone: true,
  imports: [
    CommonModule,
    PorscheDesignSystemModule,
    RouterLink,
    LetDirective,
    TranslocoPipe,
    MaybeUrlComponent,
  ],
  templateUrl: './event-details.component.html',
  styleUrl: './event-details.component.scss',
})
export class EventDetailsComponent {
  private readonly authService = inject(AuthService);
  private readonly mediaQueryService = inject(MediaQueryService);
  private readonly route = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly eventService = inject(EventService);
  readonly eventTypeTranslation = eventTypeTranslation;

  readonly isFunGamesAdmin = this.authService.isFunGamesAdmin();
  readonly mediaMaxXs$ = this.mediaQueryService.max('xs');
  event$ = this.getEvent$();

  reloadEvent(markForCheck = false): void {
    this.event$ = this.getEvent$();
    if (markForCheck) this.changeDetectorRef.markForCheck();
  }

  trackTransaction(index: number, transaction: Transaction): number {
    return transaction.id;
  }

  private getEvent$(): Observable<Event> {
    const routeParams$ = merge(
      of(this.route.snapshot.params),
      this.route.params.pipe(
        startWith(this.route.snapshot.params),
        distinctUntilChanged((a, b) => objectsEqual(a, b)),
        skip(1),
        tap(() => this.reloadEvent(true)),
        switchMap(() => EMPTY),
      ),
    );

    return routeParams$.pipe(
      switchMap((routeParams) => {
        const eventId = Number(routeParams['id']);
        return this.eventService.getEvent(eventId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
